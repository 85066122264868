import React,{useEffect} from 'react'
import "./assets/nicepage.css"
import "./assets/Satraps-landing-page.css"
import "./assets/mainstyle.css"
import { Link } from 'react-router-dom'





import { Helmet } from 'react-helmet'
const Home = () => {
  useEffect(() => {
    // Create a script element
    setTimeout(() => {
      const script = document.createElement('script');
      const script2 = document.createElement('script');

      // Set the src attribute to the URL of your JavaScript file
      script2.src = '../home/assets/jquery.js';
      script.src = '../home/assets/nicepage.js';
    

      window.onload = () => {
        document.head.appendChild(script2);
        document.head.appendChild(script);
     
      };

    }, 1000);


  }, [])
  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="keywords" content="The historians of WEB3, Satrap Domains, Satraps Court, Satraps FTSO, Satrap Docs" />
        <meta name="description" content="" />
        <title>The Satraps: Historians of Web3</title>
        <link rel="icon" href='/html/images/favicon.png' type="image/png" />
        <link rel="stylesheet" href="nicepage.css" media="screen" />
        <link rel="stylesheet" href="Satraps-landing-page.css" media="screen" />
        {/* <script type="text/javascript" src="../home/assets/jquery.js" defer=""></script> */}
        {/* <script type="text/javascript" src="../home/assets/nicepage.js" defer=""></script> */}
        <link id="u-theme-google-font" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i|Open+Sans:300,300i,400,400i,500,500i,600,600i,700,700i,800,800i" />
      </Helmet>
      <body className="u-body u-xl-mode">
        <header
          className="u-align-center-xs u-border-3 u-border-no-left u-border-no-right u-border-palette-1-dark-2 u-box-shadow u-header u-image u-shading u-header"
          id="sec-d1f9" data-animation-name="" data-animation-duration="0" data-animation-delay="0"
          data-animation-direction="" data-image-width="4166" data-image-height="2002">
          <div className="u-clearfix u-sheet u-sheet-1">
            <Link to="/"
              className="u-align-center-lg u-align-center-md u-align-center-sm u-align-center-xl u-align-left-xs u-image u-logo u-image-1"
              data-image-width="1600" data-image-height="350" title="Home" data-animation-name="" data-animation-duration="0"
              data-animation-direction="">
              <img src='/html/images/Logo2withname2.png' className="u-logo-image u-logo-image-1" data-image-width="80" />
            </Link>
          </div>
        </header>
        <section className="u-align-center u-clearfix u-grey-90 u-section-1" id="carousel_1137">
          <div className="u-clearfix u-sheet u-sheet-1">
            <p className="phead">- The Satraps Collection -
              <span className="sphead">
                The Court of Ground-up Governance
              </span>
              {/* <span className="simp-btn b-sgb">
                <a style={{ color: "#000" }} href="https://satraps.info/songbirdmint/">Mint on Songbird</a>
              </span>&nbsp;
              <span className="simp-btn b-flr">
                <a style={{ color: "#000" }} href="https://satraps.info/flaremint/">Mint on Flare</a>
              </span> */}
            </p>
    <div className="u-expanded-width u-layout-horizontal u-list u-list-1">
             {/* <a className="u-absolute-vcenter u-gallery-nav u-gallery-nav-prev u-hidden-xl u-hover-palette-5-base u-icon-circle u-opacity u-opacity-70 u-palette-2-base u-spacing-10 u-text-hover-palette-2-light-1 u-gallery-nav-1"
                href="#" role="button">
                <span aria-hidden="true">
                  <svg viewBox="0 0 451.847 451.847">
                    <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path>
                  </svg>
                </span>
                <span className="sr-only">
                  <svg viewBox="0 0 451.847 451.847">
                    <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path>
                  </svg>
                </span>
              </a>
              <a className="u-absolute-vcenter u-gallery-nav u-gallery-nav-next u-hidden-xl u-hover-palette-5-base u-icon-circle u-opacity u-opacity-70 u-palette-2-base u-spacing-10 u-text-hover-palette-2-light-1 u-gallery-nav-2"
                href="#" role="button">
                <span aria-hidden="true">
                  <svg viewBox="0 0 451.846 451.847">
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
    L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
    c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
                  </svg>
                </span> 
                <span className="sr-only">
                  <svg viewBox="0 0 451.846 451.847">
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
    L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
    c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
                  </svg>
                </span>
              </a>
              <a className="u-absolute-vcenter u-gallery-nav u-gallery-nav-prev u-hidden-xl u-hover-palette-5-base u-icon-circle u-opacity u-opacity-70 u-palette-2-base u-spacing-10 u-text-hover-palette-2-light-1 u-gallery-nav-3"
                href="#" role="button">
                <span aria-hidden="true">
                  <svg viewBox="0 0 451.847 451.847">
                    <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path>
                  </svg>
                </span>
                <span className="sr-only">
                  <svg viewBox="0 0 451.847 451.847">
                    <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path>
                  </svg>
                </span>
              </a>
              <a className="u-absolute-vcenter u-gallery-nav u-gallery-nav-next u-hidden-xl u-hover-palette-5-base u-icon-circle u-opacity u-opacity-70 u-palette-2-base u-spacing-10 u-text-hover-palette-2-light-1 u-gallery-nav-4"
                href="#" role="button">
                <span aria-hidden="true">
                  <svg viewBox="0 0 451.846 451.847">
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
    L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
    c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
                  </svg>
                </span>
                <span className="sr-only">
                  <svg viewBox="0 0 451.846 451.847">
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
    L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
    c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
                  </svg>
                </span>
              </a>
              <a className="u-absolute-vcenter u-gallery-nav u-gallery-nav-prev u-hidden-xl u-hover-palette-5-base u-icon-circle u-opacity u-opacity-70 u-palette-2-base u-spacing-10 u-text-hover-palette-2-light-1 u-text-white u-gallery-nav-5"
                href="#" role="button">
                <span aria-hidden="true">
                  <svg viewBox="0 0 451.847 451.847">
                    <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path>
                  </svg>
                </span>
                <span className="sr-only">
                  <svg viewBox="0 0 451.847 451.847">
                    <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path>
                  </svg>
                </span>
              </a>
              <a className="u-absolute-hcenter-md u-absolute-hcenter-sm u-absolute-hcenter-xs u-absolute-vcenter-lg u-absolute-vcenter-xl u-gallery-nav u-gallery-nav-next u-hidden u-hover-palette-5-base u-icon-circle u-opacity u-opacity-70 u-palette-2-base u-spacing-10 u-text-hover-palette-2-light-1 u-gallery-nav-6"
                href="#" role="button">
                <span aria-hidden="true">
                  <svg viewBox="0 0 451.846 451.847">
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
    L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
    c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
                  </svg>
                </span>
                <span className="sr-only">
                  <svg viewBox="0 0 451.846 451.847">
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
    L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
    c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
                  </svg>
                </span> 
              </a> */}
              <div className="u-repeater u-repeater-1">
                <div
                  className="u-align-left u-container-style u-custom-item u-image u-image-round u-list-item u-radius-15 u-repeater-item u-shading u-image-1"
                  data-image-width="541" data-image-height="720" data-animation-name="pulse" data-animation-duration="750"
                  data-animation-direction="" data-animation-delay="0">
                  <div className="u-container-layout u-similar-container u-container-layout-1">
                    <h2 className="effh2 u-align-center u-subtitle u-text u-text-default u-text-3">Satraps Forum</h2>
                    {/* <Link to="https://id.satraps.io/" 
                      className="u-border-1 u-border-grey-75 u-btn u-btn-round u-button-style u-hover-palette-5-dark-1 u-palette-2-base u-radius-10 u-text-active-palette-2-light-1 u-text-body-alt-color u-text-hover-palette-3-light-1 u-btn-1">Claim
                      Your ID</Link> */}
                      <div className="parent-div">
                        <a href="https://id.satraps.io/" className="link-button">
                          <button className="u-border-1 u-border-grey-75 u-btn u-btn-round u-button-style u-hover-palette-5-dark-1 u-palette-2-base u-radius-10 u-text-active-palette-2-light-1 u-text-body-alt-color u-text-hover-palette-3-light-1 u-btn-1">Claim ID</button>
                        </a> 
                        <a href="https://forum.satraps.io/" className="link-button">
                          <button className="u-border-1 u-border-grey-75 u-btn u-btn-round u-button-style u-hover-palette-5-dark-1 u-palette-2-base u-radius-10 u-text-active-palette-2-light-1 u-text-body-alt-color u-text-hover-palette-3-light-1 u-btn-1">To Forum</button>
                        </a>
                      </div>
                  </div>
                  <div className="content2">
                    <div className="content__container2">
                    <br></br><br></br><br></br>The first on-chain <span className="textred">forum</span> on Songbird<br></br>
                      Mint a Satrap ID to become a <span className="textgreen">Satrap Citizen</span> on Songbird<br></br>
                    </div>
                    <div className="navright">
                      <i>{'swipe ->'}</i>
                    </div>
                  </div>
                </div>
                <div
                  className="u-align-left u-container-style u-custom-item u-image u-image-round u-list-item u-radius-15 u-repeater-item u-shading u-image-2"
                  data-image-width="958" data-image-height="720" data-href="#" data-animation-name="pulse"
                  data-animation-duration="750" data-animation-direction="" data-animation-delay="0">
                  <div className="u-container-layout u-similar-container u-container-layout-2">
                    <h2 className="effh2 u-align-center u-subtitle u-text u-text-default u-text-4">Satraps Court</h2><Link to="https://beta.satraps.io/" 
                      className="u-border-1 u-border-grey-75 u-btn u-btn-round u-button-style u-hover-palette-5-dark-1 u-palette-2-base u-radius-10 u-text-active-palette-2-light-1 u-text-body-alt-color u-text-hover-palette-3-light-1 u-btn-1">Try Closed Beta</Link>
                  </div>
                  <div className="content2">
                    <div className="content__container2">
                    <br></br><br></br><br></br><br></br>
                      Satraps on-chain grassroots governance<br></br>
                      Help make the <span className="textred">web3</span> a safe and predictable space for <span className="textred">real-world</span> businesses<br /><br />
                      {/* Appeal Decrees<br /><br /> */}
                      {/* Establish relationships with other Senators and Satraps<br /><br /> */}
                      {/* Manage your Satrap Party<br /><br /> */}
                      {/* View and Edit Satrap Profile and History */}
                    </div>
                    <div className="navright">
                      <i>{'swipe ->'}</i>
                    </div>
                  </div>
                </div>
                <div
                  className="u-align-left u-container-style u-custom-item u-image u-image-round u-list-item u-radius-15 u-repeater-item u-shading u-image-3"
                  data-image-width="900" data-image-height="756" data-animation-name="pulse" data-animation-duration="750"
                  data-animation-direction="" data-animation-delay="0">
                  <div className="u-container-layout u-similar-container u-container-layout-3">
                    <h2 className="effh2 u-align-center u-subtitle u-text u-text-default u-text-5">FTSO Dashboard</h2>
                    <Link to="/ftso/#/"
                      className="u-border-1 u-border-grey-75 u-btn u-btn-round u-button-style u-hover-palette-5-dark-1 u-palette-2-base u-radius-10 u-text-active-palette-2-light-1 u-text-body-alt-color u-text-hover-palette-3-light-1 u-btn-3"
                    >Open Dashboard</Link>
                  </div>
                  <div className="content2">
                    <div className="content__container2"><br /><br /> <br />
                    Access the Satraps Ethical FTSO dashboard, where you can delegate to ethical TSOs on the <span class="textred">Songbird</span> and the <span class="textred">Flare
                  Networks</span>
                    </div>
                    <div className="navright">
                      <i>{'swipe ->'}</i>
                    </div>
                  </div>
                </div>
                <div
                  className="u-align-left u-container-style u-custom-item u-image u-image-round u-list-item u-radius-15 u-repeater-item u-shading u-image-4"
                  data-image-width="564" data-image-height="602" data-animation-name="pulse" data-animation-duration="750"
                  data-animation-direction="" data-animation-delay="0">
                  <div className="u-container-layout u-similar-container u-container-layout-4">
                    <h2 className="effh2 u-align-center u-subtitle u-text u-text-default u-text-6">Satrap Docs</h2>
                    <Link to="https://satraps.info"
                      className="u-border-1 u-border-grey-75 u-btn u-btn-round u-button-style u-hover-palette-5-dark-1 u-palette-2-base u-radius-10 u-text-active-palette-2-light-1 u-text-body-alt-color u-text-hover-palette-3-light-1 u-btn-4"
                      >Read</Link>
                  </div>
                  <div className="content2">
                    <div className="content__container2">
                      Everything you need to know about the Satraps platform
                    </div>
                  </div>
                </div>
              </div>
              <a className="u-absolute-vcenter u-gallery-nav u-gallery-nav-prev u-grey-70 u-icon-circle u-opacity u-opacity-70 u-spacing-10 u-text-white u-gallery-nav-7"
                href="#" role="button">
                <span aria-hidden="true">
                  <svg viewBox="0 0 451.847 451.847">
                    <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path>
                  </svg>
                </span>
                <span className="sr-only">
                  <svg viewBox="0 0 451.847 451.847">
                    <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"></path>
                  </svg>
                </span>
              </a>
              <a className="u-absolute-vcenter u-gallery-nav u-gallery-nav-next u-grey-70 u-icon-circle u-opacity u-opacity-70 u-spacing-10 u-text-white u-gallery-nav-8"
                href="#" role="button">
                <span aria-hidden="true">
                  <svg viewBox="0 0 451.846 451.847">
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
    L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
    c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
                  </svg>
                </span>
                <span className="sr-only">
                  <svg viewBox="0 0 451.846 451.847">
                    <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
    L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
    c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </section>
        <footer className="u-clearfix u-footer u-grey-80" id="sec-95a2">
          <div className="u-clearfix u-sheet u-sheet-1">
            <Link to="/" className="u-image u-logo u-image-1" data-image-width="250" data-image-height="250">
              <img src='/assets/logov1.png' className="u-logo-image u-logo-image-1" />
            </Link>
            <div className="u-align-left u-social-icons u-spacing-10 u-social-icons-1">
              {/* <a className="u-social-url" target="_blank" data-type="Custom" title="Songbird Domains"
                href="https://songbird.domains/"><span className="u-file-icon u-icon u-social-custom u-social-icon u-icon-1"><img
                  src='/html/images/logo.95e0ba4d.png' alt="" /></span>
              </a> */}
              <a className="u-social-url" target="_blank" data-type="Custom" title="The Satraps Twitter"
                href="https://twitter.com/TheSatraps"><span
                  className="u-file-icon u-icon u-social-custom u-social-icon u-icon-3"><img src={'/html/images/twitterX1.png'}
                    alt="" /></span>
              </a>
              <a className="u-social-url" data-type="Email" title="Contact us" href="mailto:clavi@satraps.io">
                <img src='/html/images/emaillogo.png' width="23" height="23" />
              </a>
            </div>
          </div>
          <span class="copyright">&copy; 2024 The Satraps Collection. All rights reserved.</span>
        </footer>
      </body>
    </>

  )
}

export default Home